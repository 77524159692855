import * as React from "react"
import { Container, Row, Col, Form  } from "react-bootstrap"
import {Link}  from "gatsby"
import {Helmet} from "react-helmet";
import Seo from "../../../../components/seo"
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import Valuation from "../../../../components/Valuation/ValuationForm/HomeVisitValuation"


const HomeVisitValuation = (props) => (
    <div className="wrapper home-visit-valuation-page">
    <Header/>
      <Seo title="Wards | Home Visit Valuation" description="Looking to see how much your property in Kent is worth? Find out how much your home is worth by talking to one of our expert agents at Wards.  " />
      <Valuation />
      <Footer />

    </div>
  )
  
  export default HomeVisitValuation
  